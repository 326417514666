const promotionsReportsLabels = {
  EBLASTS: "Eblasts",
  NEWSLETTER_ADVERTISING: "Newsletter advertising",
  SPONSORS: "Sponsors",
  SITE_ADVERTISING: "Site advertising",
  TOWNS: "Towns",
  COMPANY_CATEGORY: "Company category",
  COMPANY_SUBCATEGORY: "Company subcategory",
  NO_SUBCATEGORIES: "No subcategories",
  ALL_SUBCATEGORIES: "All subcategories",
  ALL_CATEGORIES: "All categories",
  END_DATE: "End date",
  START_DATE: "Start date",
  FILTER_BY_WEEK: "Filter by week",
  ALL_WEEKS: "All weeks",
  GENERATE_REPORT: "Generate report",
  RESERVATIONS_LABEL: "Reservations",
  RESERVATIONS_CHECKBOX: "Include reservations",
  REPORTS_FOR_WEEK:
    "Reports for week :week_number: ( :from_date: - :to_date: )",
  REPORTS_FROM_TO: "Reports from :from_date: to :to_date:",
  ERRORS: {
    END_DATE: "Must be after start date",
    API: "There was an error fetching the report: :errors:. Please try again.",
  },
  NO_RESULTS_FOUND: "No results found for your search",
  TYPE: "Type",
  SITE_AND_NEWSLETTER: "Sponsors for newsletter & site",
};

export default promotionsReportsLabels;
