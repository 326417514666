const promotionsLabels = {
  SECTION_STATUS: {
    SUCCESS: "Completed",
    ERROR: "There are items that require your attention",
  },
  HELPER_LINKS: {
    GAM_REPORT: "GAM Report",
    NEW_CONTRACT: "Online contract",
    EDITABLE_CONTRACT: "Editable contract",
  },
  SUMMARY_ACTIONS: {
    APPROVE: "Approve",
    APPROVING: "Approving...",
    APPROVED: "Approved",
    DISAPPROVE: "Disapprove",
    SEND_TO_GAM: "Re-send to GAM",
    SENDING: "Sending...",
    COMPLETE: "Complete",
    COMPLETING: "Completing...",
    COMPLETED: "Completed",
    GENERATING: "Generating Billing Entry...",
    GENERATE: "Generate Billing Entry",
    REQUEST_REOPEN: "Request Reopen",
    ACTIVATE_OR_DIACTIVATE_FORBIDEN_MESSAGE:
      "You need more than 1 creative for this functionality",
    PAUSE: "Pause",
    DELIVER: "Deliver",
  },
  RECEIVES: "Receives",
  SEND_PREVIEW_E_BLAST: "Send preview E-blast",
  CREATIVES: {
    SIZE: "Size",
    TOWNS: "Towns",
    UPLOADED_FILE_SUCCESS: "Uploaded file successfully",
    CHOOSE_VIDEO_AD: "Choose video ad",
    CREATIVE_NAME: "Creative name",
    VIDEO_NAME: "Video name",
    RECOMMENDATION_VIDEO_TITLE: "Video recommendations",
  },
  BILLING_INFO: {
    SPLITS: "Splits",
    SELECT: "Select",
    NO_TOWN_OR_PARTNER_SELECTED: "No town/partner selected",
    MONTH_S: "month(s)",
    REQUEST_CONTRACT_END_DATE_EXTENSION: "Request contract end date extension",
    REQUESTED_END_DATE: "Requested end date: ",
    PLEASE_WAIT_FOR_APPROVAL: "Please wait for approval.",
    REQUESTED_DATE_EXTENSION_TO: "Requested contract end date extension to ",
    DATE_EXTENSION_CONFIRMATION_MESSAGE:
      ":action the contract extension request?",
    REJECT_DATE_EXTENSION_CONFIRMATION_MESSAGE:
      "Reject contract end date extension",
    DATE_EXTENSION_ERROR: "Error sending your request. Please try again.",
    DATE_EXTENSION_SUCCESS: "Your request has been sent.",
    ADD_ADDITIONAL_TOWN_PARTNER: "Add additional town/partner",
    SPLIT_BY: "Splits by",
  },
  DIALOGS: {
    DESKTOP_LEADERBOARD_MESSAGE:
      "The Desktop Leaderboard needs a Mobile Leaderboard too, do you want to continue?",
    MOBILE_LEADER_BOARD_MESSAGE:
      "The Mobile Leaderboard needs a Desktop Leaderboard too, do you want to continue?",
  },
  SPONSORS: {
    RECOMMENDATION_IMAGE_TITLE: "Sponsor image recommendations",
  },
  CREATED_BY: "Created by: ",
  NOTES: "Notes",
  RESERVATIONS: {
    GENARATING: "Generating...",
    VALIDATE_AND_GENERATE: "Validate & Generate",
  },
  APPROVE_CONFIRMATION_MESSAGE_TITLE: "Approve Confirmation",
  APPROVE_CONFIRMATION_MESSAGE_CONTENT:
    "Are you sure you want to approve this file?",
  ARCHIVE: "Archive",
  INVOICES: {
    RECEIVE_PAYMENT_TITLE: "Receive invoice Payment",
    RECEIVE_PAYMENT_TITLE_FOR_MOBILE: "Receive",
  }
};

export default promotionsLabels;
